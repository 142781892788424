<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-cloud-check"
          main-icon-background-color-class="success lighten-5"
          main-icon-text-color="success--text"
          sub-heading-text="Facturas procesadas Correctamente"
          :heading-text="$store.state.statistics.listStatisticsInvoice.Mensaje[0].procesadas.toString()"
          :bottom-button-text='$store.state.statistics.listStatisticsInvoice.Mensaje[0].porcentProcesadas'
          bottom-button-icon="mdi-percent"
          bottom-button-hover-class="hover:bg-primary"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-cloud-sync"
          main-icon-background-color-class="warning lighten-5"
          main-icon-text-color="warning--text"
          sub-heading-text="Facturas pendientes por procesar"
          :heading-text="$store.state.statistics.listStatisticsInvoice.Mensaje[0].pendientes.toString()"
          :bottom-button-text="$store.state.statistics.listStatisticsInvoice.Mensaje[0].porcentPendientes"
          bottom-button-icon="mdi-percent"
          bottom-button-hover-class="hover:bg-success"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-cloud-alert"
          main-icon-background-color-class="error lighten-5"
          main-icon-text-color="error--text"
          sub-heading-text="Facturas rechazadas"
          :heading-text="$store.state.statistics.listStatisticsInvoice.Mensaje[0].errores.toString()"
          :bottom-button-text="$store.state.statistics.listStatisticsInvoice.Mensaje[0].porcentErrores"
          bottom-button-icon="mdi-percent"
          bottom-button-hover-class="hover:bg-info"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="3"
        sm="6"
      >
        <analytic-one-card
          main-icon-name="mdi-cloud-upload"
          main-icon-background-color-class="info lighten-5"
          main-icon-text-color="info--text"
          sub-heading-text="Total de facturas enviadas"
          :heading-text="$store.state.statistics.listStatisticsInvoice.Mensaje[0].total.toString()"
          bottom-button-text="100"
          bottom-button-icon="mdi-percent"
          bottom-button-hover-class="hover:bg-warning"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import analyticOneCard from '@/components/card/AnalyticCardVersionOne'

  import {
    analyticOne,
  } from '@/data/analytic2'
  import { mapState } from 'vuex'
  export default {
    name: 'Statistics',
    metaInfo: {
      title: 'Statistics',
    },
    components: {
      'analytic-one-card': analyticOneCard,
    },
    data () {
      return {
        analyticOne,
      }
    },
    computed: {
      ...mapState('statistics', ['listStatisticsInvoice']),
    },
    created () {
      this.getStatistics()
    },
    methods: {

      async getStatistics () {
        await this.$store.dispatch('statistics/getStatistics')
      },
    },
  }
</script>
